<template>
  <div>
    <h1 class="display-4 font-weight-light text-center">
    <template
      v-if="backLink"
    >
      <v-btn
        icon
        class="float-left"
        @click="goTo(backLink)"
      >
        <v-icon
          size="24"
        >
          mdi-arrow-left
        </v-icon>
      </v-btn>
    </template>

    <template
      v-if="back"
    >
      <v-btn
        icon
        class="float-left"
        @click="$router.go(-1)"
      >
        <v-icon
          size="24"
        >
          mdi-arrow-left
        </v-icon>
      </v-btn>
    </template>

    <slot />
  </h1>

  <h4>
    <slot name="subheading" />
  </h4>
  </div>
</template>

<script>
  export default {
    name: 'PagesHeading',
    props: {
      backLink: {
        type: String,
        default: null
      },
      rightLink: {
        type: String,
        default: null
      },
      back: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
